html, body, #root {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}


.loader {

  width: 8vw;
  height: 8dvw;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}


/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  overflow: hidden; /* Ensure no extra scrolling */
}




.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}


.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;

}
.detection-state {
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  font-size: x-large;
  margin-top: 50;
}


.acuant-camera
{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100dvh;
  z-index: 99999;
  background-color: #000;

}

.acuant-camera video {
  width: 100%;
  height: 100dvh;
  object-fit: cover; 
}
#acuant-ui-canvas {
  top:16dvw!important;
}


.face-camera-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100dvh;

}
.fit-screen {
  max-width: 100%;
  height: auto;
}

.MainBox{
  margin-top: 100px;
  border: rgb(220 220 220);
  border-style: solid;
  border-width: thin;
  border-radius: 9px;
  max-width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;;
}


.illu1{
  margin-top:10px;
  margin-bottom:5px;
  width: 40vw;
}

.illu2{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 35vw;
  border-style: solid;
  border-color: #9ab9f352;
  border-width: thick;

}

.spa{

    color: black;
    font-weight: 500;
}

 ul {
  list-style:none;
}

li.checked:before{
  content: '✓ ';
  color: green;
  font-size: 22px;
}

li.mose:before{
  content: '- ';
  color: rgb(3, 3, 3);
  font-size: 22px;
}



li.unchecked::before {
  content: '↺ ';
  color: #4797f4;
  font-size: 22px;
}

.mtext322{padding: 5px 20px 3px 20px;}

.smallLogo{
  width: 50px;
  height: 50px;
}
.errormolo{
  margin-left: 20px;
    margin-right: 20px;
}
.mobilehide{
  display: block;
}


.mobileshow { 
  display:none; 
  }

  @media screen and (max-width: 500px) {
  .mobileshow { 
  display:block; }

  .navbar-brand mb-0 h1{font-size: 1.05rem;}

  .mobilehide{
    display: none;
  }
  }

  

  